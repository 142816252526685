import React from 'react'
import { Helmet } from "react-helmet"

export default function Head() {
    
    return (
        <Helmet>
            <meta charSet="utf-8" />

            {/* <!-- Primary Meta Tags --> */}
            <title>Transport Kraków GRANTRANS</title>
            <meta name="title" content="Transport Kraków GRANTRANS" />
            <meta name="description" content="Usługi transportowe. Klienci indywidualni, biznesowi i grupy zorganizowane. Dostępne limuzyny, suvy, busy i autokary. " />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://grantrans.pl/" />
            <meta property="og:title" content="Transport Kraków GRANTRANS" />
            <meta property="og:description" content="Usługi transportowe. Klienci indywidualni, biznesowi i grupy zorganizowane. Dostępne limuzyny, suvy, busy i autokary. " />
            <meta property="og:image" content="https://grantrans.pl/logo/grantrans_logo.jpg" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://grantrans.pl/" />
            <meta property="twitter:title" content="Transport Kraków GRANTRANS" />
            <meta property="twitter:description" content="Usługi transportowe. Klienci indywidualni, biznesowi i grupy zorganizowane. Dostępne limuzyny, suvy, busy i autokary. " />
            <meta property="twitter:image" content="https://grantrans.pl/logo/grantrans_logo.jpg" />

            <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16" />

            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        </Helmet>
    )
};
