import React from 'react'
 import { Link } from 'gatsby-plugin-intl';

import styles from './MenuButton.module.css';


export default function MenuButton(props) {
    return (
    <div className={styles.container}>        
        <Link to={`/${props.link}`} className={styles.text}>
            <div dangerouslySetInnerHTML={{__html: props.title}}/>
        </Link>
    </div>
    )
}
