import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import styles from './LanguageMenu.module.css';


const choosenOptionStyle = {color: 'red'};

export default function LanguageMenu() {
    const intl = useIntl()
    const enStyle = intl.locale === 'en' ? choosenOptionStyle : {};
    const plStyle = intl.locale === 'pl' ? choosenOptionStyle : {};
    
    return (
        <div className={styles.container}>
            <Link to='/'>
                <p className={styles.option} style={plStyle}>PL</p>
            </Link>
            <p>|</p>
            <Link to='/en'>
                <p className={styles.option} style={enStyle}>EN</p>
            </Link>
        </div>        
    )
}
