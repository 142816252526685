import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './WhatsappAnchor.module.css';


const imgStyle = {width: '73px', height: '73px', background: '#57ba63'};


export default function WhatsappAnchor() {
    const data = useStaticQuery(graphql`
        query {
            fbLogo: file(relativePath: { eq: "whatsapp.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <a href='https://wa.me/48602599660' target='_blank' rel='noreferrer'>
            <div className={styles.container}>
                <Img fluid={data.fbLogo.childImageSharp.fluid} style={imgStyle}/>
            </div>
            <div className={styles.mobile}>
                <img src='whatsapp_mobile.svg' className={styles.icon}/>
            </div>
        </a>
    )
};
