import React from 'react'

import styles from './FooterBar.module.css'


export default function FooterBar() {    
    return (
    <>
        <img src={'/logo/footer.svg'} alt="footer" className={styles.element}/>
        <div className={styles.container}>
            <h1>
                GRANTRANS, Andrzej Grymek 
                &#183; mobile: <a href="tel:+48602599660">+48 602 599 660</a>
                &#183; <span className={styles.noWrap}>
                    e-mail: <a href="mailto:biurograntrans@gmail.com">biurograntrans@gmail.com</a>
                </span>
            </h1>
        </div>
    </>
    )
}
