import React from 'react'

import styles from './HeaderBar.module.css';
import LanguageMenu from './LanguageMenu';
import Menu from './Menu'
import LandscapeImg from './LandscapeImg';
import Logo from './Logo';
import FacebookAnchor from './FacebookAnchor';
import WhatsappAnchor from './WhatsappAnchor';


export default function HeaderBar() {
    return (
    <>        
        <LandscapeImg/>
        <div className={styles.headerContainer}>
            <div style={{flex: 1}}/>
            <Menu/>
            <LanguageMenu/>
        </div>
        <Logo/>
        <WhatsappAnchor/>
        <FacebookAnchor/>
    </>
    )
}
