export function compareByImgName(imgObject1, imgObject2) {
    const img1 = parseInt(imgObject1.node.name.match(/\d+/g).join(''), 10);
    const img2 = parseInt(imgObject2.node.name.match(/\d+/g).join(''), 10);
 
     if (img1 > img2) {
        return 1;
    } else if (img1 < img2) {
        return -1;
    } 
 
    return 0;
}


export function compareByOrderParam(carObject1, carObject2) {
    const c1 = carObject1.node.frontmatter.order;
    const c2 = carObject2.node.frontmatter.order;
 
     if (c1 > c2) {
        return 1;
    } else if (c1 < c2) {
        return -1;
    } 
 
    return 0;
}


export function compareByTime(img1, img2) {
    const date_1 = Date.parse(img1.node.atime);
    const date_2 = Date.parse(img2.node.atime);

    if (date_1 > date_2) { 
        return -1;
    } else if (date_1 < date_2) {
        return 1;
    }

    return 0;
}
