import React from 'react'

import Head from '../../components/head'
import FooterBar from '../FooterBar/FooterBar'
import HeaderBar from '../HeaderBar/HeaderBar'


export default function Layout({ children }) {
    return (
    <>
        <div id='top'/>
        <Head />

        <HeaderBar/>
        {children}
        <FooterBar/>
    </>
    )
}
