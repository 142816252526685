import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './LandscapeImg.module.css'


const highResolution = {width: '77%', height: '12.7vw'};
const lowResolution = {width: '79.5%', height: '16vw'};
const imgStyle = {objectPosition: '0% 43%'};


export default function LandscapeImg() {
    const data = useStaticQuery(graphql`
        query {
            fbLogo: file(relativePath: { eq: "krakow.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);



    return (
        <div className={styles.container}>
            <Img fluid={data.fbLogo.childImageSharp.fluid} style={highResolution} imgStyle={imgStyle} className={styles.desktop}/>
            <Img fluid={data.fbLogo.childImageSharp.fluid} style={lowResolution} imgStyle={imgStyle} className={styles.mobile}/>
        </div>
        
    )
}
