import React, { useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import styles from './Menu.module.css';
import MenuButton from "./MenuButton"

export default function SiteMenu() {
    const intl = useIntl();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 350) {
                document.querySelector('#returnButton').style.opacity = 1;
            } else {
                document.querySelector('#returnButton').style.opacity = 0;
            }
        });
    });

    return (
    <>
    <div className={styles.returnButton} id='returnButton'>
        <MenuButton title={intl.formatMessage({ id: 'menu.mainView' })} link='#top'/>
    </div>
    <div className={styles.container}>
        <MenuButton title={intl.formatMessage({ id: 'menu.offer' })} link='#OffersSection'/>
        <MenuButton title={intl.formatMessage({ id: 'menu.fleet' })} link='#FleetSection'/>
        <MenuButton title={intl.formatMessage({ id: 'menu.contact' })} link='#ContactSection'/>
        <MenuButton title={intl.formatMessage({ id: 'menu.aboutCompany' })} link='#AboutUsSection'/>
        <MenuButton title={intl.formatMessage({ id: 'menu.gallery' })} link='#GalerySection'/>
    </div>
    </>
    )
}
