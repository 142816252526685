import React from 'react'
import { Link } from 'gatsby-plugin-intl'

import styles from './Logo.module.css'


export default function Logo() {
    return (
        <Link to="/#top">
            <div className={styles.container}>
                <img src={'/logo/logo.svg'} alt="logo" className={styles.logo}/>
            </div>
        </Link>
    )
}
